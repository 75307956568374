import { Toast } from 'vant';

export default {
	methods: {
		// 路由跳转
		go (path, query, isLoading) {
			if (typeof path === 'number') {
				this.$router.go(path);
				return;
			}
			path = path.trim();// eslint-disable-line
			if (/http|https/.test(path)) {
				window.location.href = path;
			} else {
				if (isLoading) {
					this.$store.commit('toggleFullPageLoading', true);
				}
				this.$router.push({ path: path, query: query || {} });
			}
		},
		// 路由跳转
		replace (obj) {
			this.$router.replace(obj);
		},
		/**
         * 全局通用的 消息弹框
         * @param {*} message
         * @param {*} type success、 fail
         * @param {*} duration 展示时间，毫秒为单位
         */
		elMsg (message = '操作成功', type = 'success', cb, duration = 1000) {
			Toast({
				type,
				message,
				duration,
				onClose: cb || function () {}
			});
		},
		elMsgHandleCb (cb, msg, type) {
			this.elMsg(msg, type, cb, 1000);
		}
	}
};