<template>
	<div ref="app">
		<v-loading></v-loading>
		<router-view></router-view>
	</div>
</template>
<script>
import vLoading from '@/components/Loading';
import mixin from '@/mixins/main';

export default {
	components: {
		vLoading
	},
	created () {
	},
	mixins: [mixin],
	mounted () {
		// this.$refs.app.style.height = document.documentElement.clientHeight + 'px';
	}
};
</script>