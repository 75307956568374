var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-overlay",
    { attrs: { show: _vm.isPageLoading } },
    [
      _c("van-loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isPageLoading,
            expression: "isPageLoading",
          },
        ],
        attrs: { type: "spinner", size: "24px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }