const Main = {
	state: {
		// 是否展示全屏loading
		isPageLoading: false
	},
	mutations: {
		// 展示/隐藏全屏loading
		toggleFullPageLoading (state, loading = false) {
			state.isPageLoading = loading;
		}
	}
};

export default Main;