/* eslint-disable */
import mainMixin from './mixins/main.js';

const mergeMixin = (component) => {
    let mixins = component.default.mixins || [];
    mixins = mixins.concat(mainMixin);
    component.default.mixins = mixins;
    return component;
};

const Home = (resolve) => {
    import('./views/home.vue').then((component) => {
        resolve(mergeMixin(component));
    });
};
const Thome = (resolve) => {
    import('./views/t-home.vue').then((component) => {
        resolve(mergeMixin(component));
    });
};
const mhHome = (resolve) => {
    import('./views/mh-home.vue').then((component) => {
        resolve(mergeMixin(component));
    });
};
const smbHome = (resolve) => {
    import('./views/smb-home.vue').then((component) => {
        resolve(mergeMixin(component));
    });
};
const Success = (resolve) => {
    import('./views/success.vue').then((component) => {
        resolve(mergeMixin(component));
    });
};

const routes = [
    {
        path: '/service',
        name: 'home',
		component: Home,
		meta: { title: '健康服务', pathKey: '1' },
		children: []
	},
	// 水滴平台 提单页
    {
        path: '/t-service',
        name: 'tHome',
		component: Thome,
		meta: { title: '', pathKey: '1' },
		children: []
	},
	// 美华保险 提单页
    {
        path: '/mh-service',
        name: 'mhHome',
		component: mhHome,
		meta: { title: '', pathKey: '1' },
		children: []
	},
	// 24市民保 提单页
    {
        path: '/smb-service',
        name: 'smbHome',
		component: smbHome,
		meta: { title: '', pathKey: '1' },
		children: []
	},
    {
        path: '/success',
        name: 'success',
		component: Success,
		meta: { title: '健康服务', pathKey: '1' },
		children: []
	},
    {
        path: '*',
        redirect: '/service'
    }
];

export default routes;