import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vueInit from './mixins/vue-init.js';
import eruda from 'eruda'; // 引入工具包

import 'vant/lib/index.css';
import '@/assets/common.less';
import {
	Loading,
	Tabbar,
	TabbarItem,
	Field,
	Form,
	Popup,
	Switch,
	Area,
	Toast,
	Swipe,
	SwipeItem,
	Lazyload,
	List,
	PullRefresh,
	Overlay,
	CheckboxGroup,
	RadioGroup,
	Radio,
	Grid,
	Cell,
	CellGroup,
	GridItem,
	Uploader,
	ImagePreview,
	Image,
	DropdownMenu,
	DropdownItem,
	Popover,
	DatetimePicker,
	Picker,
	Cascader,
	Calendar,
	Rate/* ,
	NumberKeyboard */
} from 'vant';

Vue.config.productionTip = false;
Vue.use(Loading);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Popover);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Field);
Vue.use(Form);
Vue.use(Popup);
Vue.use(Switch);
Vue.use(Area);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(CheckboxGroup);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Overlay);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Uploader);
Vue.use(Rate);
Vue.use(ImagePreview);
Vue.use(Image);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Cascader);
Vue.use(Calendar);
// Vue.use(NumberKeyboard);

const vue = new Vue({
	router,
	store,
	mixins: [vueInit],
	render: h => h(App)
}).$mount('#app');
window.__vue = vue;

if (process.env.NODE_ENV !== 'production') {
	eruda.init(); // 初始化
}