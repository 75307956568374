import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes.js';

Vue.use(Router);
const router = new Router({
	mode: 'history',
	routes
});

router.beforeEach((to, from, next) => {
	document.title = to.meta.title;
	next();
});

export default router;