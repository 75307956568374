<template>
	<van-overlay :show="isPageLoading">
		<van-loading v-show="isPageLoading" type="spinner" size="24px" />
	</van-overlay>
</template>

<script>
export default {
	data () {
		return {
			isPageLoading: false
		};
	},
	watch: {
		'$store.state.main.isPageLoading' (val) {
			this.isPageLoading = val;
		}
	}
};
</script>
<style lang="less" scoped>
    .loading {
        font-size: 50px;
    }
	/deep/.van-loading {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
	}
</style>